/** @jsx jsx */
import { jsx, Card } from 'theme-ui';
import Layout from '../../components/layout';
import Hero, { HeroLayout } from '../../components/hero';
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import Article from '../../components/article'
import { graphql } from 'gatsby'
import Promo from '../../components/promo'
import { Stack } from 'raam'
import MetaData from '../../components/metadata'

const PartnerSingle = (props) => {

  const page = props.data?.contentfulPartner
  const parent = props.data?.contentfulPage
  const hasByline = parent?.bylineMain && parent?.bylineAside

  const logoSX = {
    width: '100%',
    p: 4,
  }

  const Logo = () => {
    if (!page?.logo?.file?.url)
      return null

    return (
      <Card variant="slim">
        <img src={page?.logo?.file?.url} alt={page.title} sx={logoSX} />
      </Card>
    )
  }

  return (
    <Layout {...props} light>
      <MetaData title={page?.title} />

      {parent?.hero &&
        <Hero 
          back='/partners'
          data={
            parent?.hero
              ? parent.hero.map(x => ({...x, title: page?.title}))
              : false
          } 
          strap={parent?.strap}
          sx={{marginBottom: hasByline ? '0px' : 6}} 
          variant={parent?.theme} 
        />
      }

      <Stack gap={4} mb={12}>
        {page?.article &&
          <Wrapper sx={{mt: 5}}>
            <Inner>
              <Article 
                {...page.article}
                forceAside={page?.article?.aside ? false : true}
                asideBefore={<Logo />}
              />
            </Inner>
          </Wrapper>
        }

        {page?.promo &&
          <Wrapper>
            <Inner>
              <Promo data={page?.promo} dark={true} />
            </Inner>
          </Wrapper>
        }
      </Stack>
        
    </Layout>
  )
}

export default PartnerSingle

export const pageQuery = graphql`
  query PartnerBySlug($slug: String!) {
    contentfulPartner(slug: {eq: $slug}) {
      title
      logo {
        file {
          url
        }
      }
      article {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
      promo {
        ...PromoFragment
      }
    }

    contentfulPage(slug: {eq: "partners"}) {
      title
      theme
      strap
      bylineMain
      bylineAside
      hero {
        ...HeroFragment
      }
    }
  }
`